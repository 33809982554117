import React from "react"
//import Layout from "../components/layout"

export default () => (
<div>
    <head>
        <script src="js/modernizr.js"></script>
        
        <title>Pranav Anandarao</title>
    </head>
    <div class="animated-title">
        <div class="text-top">
            <div>
                <span id="intro">Welcome</span>
                <span id="name">I'm Pranav</span>
            </div>
        </div>
        <div class="text-bottom">
            <div class="ms-slider">
                <ul class="ms-slider__words">
                    <li class="ms-slider__word">Computer Engineer</li>
                    <li class="ms-slider__word">Software Developer</li>
                    <li class="ms-slider__word">Data Scientist</li>
                    <li class="ms-slider__word">Computer Engineer</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="buttons">
        <a href="/about" class="btn effect01"><span>Learn More</span></a>
    </div>
</div>
)